import { DEBUG, MOCK_AUTH_API, BACKEND_BASE_URL } from "./../../constants.js";
import axios from "axios";
import router from "@/router";

class ProjectsInterface {

}

class MockProjects extends ProjectsInterface {

}

class Projects extends ProjectsInterface{

    // Методы проектов
    getProjects(limit, offset, desc, sortType, id, owner){
        if (id && owner){
            return axios.get(`api/v1/projects/?id=${id}&limit=${limit}&offset=${offset}&sort=${desc}${sortType}&manager_id=${owner}`, {
                params: {
                    currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
                }
            });
        } else if (id){
            return axios.get(`api/v1/projects/?id=${id}&limit=${limit}&offset=${offset}&sort=${desc}${sortType}`, {
                params: {
                    currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
                }
            });
        } else if (owner){
            return axios.get(`api/v1/projects/?&limit=${limit}&offset=${offset}&sort=${desc}${sortType}&manager_id=${owner}`, {
                params: {
                    currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
                }
            });
        }
         else return axios.get(`api/v1/projects/?limit=${limit}&offset=${offset}&sort=${desc}${sortType}`, {
                params: {
                    currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
                }
            });
    }
    allProjects() {
        return axios.get('/api/v1/projects/', {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }
    addNewProject(title, client){
        return axios.post(`/api/v1/projects/`, {
            title, client
        }, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    copyProject(project_pk){
        return axios.post(`/api/v1/projects/${project_pk}/copy/`, {}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    deleteProject(id){
        return axios.delete(`/api/v1/projects/${id}/`, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    getProject(id, currency=null){
        return axios.get(`/api/v1/projects/${id}/`, {
            params: {
                currency: currency || localStorage.getItem('user.currency'),
            }
        });
    }

    // Методы помещений
    getRooms(id){
        return axios.get(`/api/v1/projects/${id}/rooms/?offset=0`, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    addRoom(id, title){
        return axios.post(`/api/v1/projects/${id}/rooms/`, {title}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    updateRoomOrder(project_pk, title, rooms_order) {
        return axios.put(`/api/v1/projects/${project_pk}/`, {title, rooms_order}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    renameRoom(project_pk, id, title){
        return axios.put(`/api/v1/projects/${project_pk}/rooms/${id}/`, {title}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    leaveRoomComment(project_pk, id, title, comment) {
        return axios.put(`/api/v1/projects/${project_pk}/rooms/${id}/`,  {title, comment}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    copyRoom(project_pk, id){
        return axios.post(`/api/v1/projects/${project_pk}/rooms/${id}/copy/`, {}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    copyRoomToAnotherProject(owner_project_id, room_id, destination_project_id){
        return axios.post(`/api/v1/projects/${owner_project_id}/rooms/${room_id}/copy/?destination_project_id=${destination_project_id}`, {}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    changeVisibleRoom(project_pk, id, is_visible){
        return axios.post(`/api/v1/projects/${project_pk}/rooms/${id}/changevisible/`, {is_visible}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    deleteRoom(project_pk, id){
        return axios.delete(`/api/v1/projects/${project_pk}/rooms/${id}/`, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }

    // Методы зон
    getZones(project_pk, room_pk){
        return axios.get(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/?offset=0`, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    addZone(project_pk, room_pk, title){
        return axios.post(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/?offset=0`, {title}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    renameZone(project_pk, room_pk, id, title){
        return axios.put(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/`, {title}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    leaveZoneComment(project_pk, room_pk, id, title, comment) {
        return axios.put(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/`,  {title, comment}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    updateZoneOrder(project_pk, id, title, areas_order) {
        return axios.put(`/api/v1/projects/${project_pk}/rooms/${id}/`, {title, areas_order}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    copyZone(project_pk, room_pk, id){
        return axios.post(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/copy/`, {}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    copyZoneToAnotherProject(owner_project_id, room_id, area_id, destinationRoomId){
        return axios.post(`/api/v1/projects/${owner_project_id}/rooms/${room_id}/areas/${area_id}/copy/?destinationRoomId=${destinationRoomId}`, {}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    changeVisibleZone(project_pk, room_pk, id, is_visible){
        return axios.post(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/changevisible/`, {is_visible}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    deleteZone(project_pk, room_pk, id){
        return axios.delete(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/`, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }

    // Методы продуктов
    addToZone(count, product_id, id, project_pk, room_pk){
        return axios.post(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/add/?count=${count}&product_id=${product_id}`, undefined, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    addItemToZone(area, id, count, discount) {
        return axios.post(`/api/v2/area-items/`,
            {
                area: area,
                product: id,
                count: count,
                discount: discount
            }, {
                params: {
                    currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
                }
            })
    }
    updateItem(areaItemId, price) {
        return axios.patch(`/api/v1/area-items/${areaItemId}`, {
            price: price ? price : '0',
        }, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }
    deleteItem(areaItemId) {
        return axios.delete(`/api/v2/area-items/${areaItemId}/`, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }
    updateProductOrder(project_pk, room_pk, id, title, items_order) {
        return axios.put(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/`, {title, items_order}, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }

    getProducts(id, project_pk, room_pk){
        return axios.get(`/api/v1/projects/${project_pk}/rooms/${room_pk}/areas/${id}/items/`, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        });
    }

    sendToCart(projectId){
        return axios.post(`/api/v1/projects/${projectId}/ordering/?type=basket`, undefined, {
            params: {
                currency: localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined,
            }
        })
    }

    updateProject(projectId, data, currency=null){

        return axios.put(`/api/v1/projects/${projectId}/`, data, {
            params: {
                currency: currency || localStorage.getItem('user.currency'),
            }
        })
    }

}

export default function projectsFactory() {
    if (MOCK_AUTH_API) {
        return new MockProjects();
    } else {
        return new Projects();
    }
}

