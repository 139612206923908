<template>
  <div v-if="projectData">

    <b-overlay :show="dataLoading" rounded="sm">
      <div class="project-view-root ">
        <LeftTree class="left-tree"></LeftTree>
        <div class="d-flex flex-grow-1">
          <!--          Отображение всего проекта сразу           -->
          <div v-if="selectedType === 'project'" class="w-100">
            <!-- Форма с табами -->
            <b-card no-body class="pl-1 w-100">
              <b-tabs pills card>
                <b-tab title="Регистрация проекта" active>
                  <b-form-group class="ml-0 ml-sm-2">
                    <b-row>
                      <b-col cols="12" sm="6">
                        <BlInput label="Название проекта"
                                 rules="required"
                                 input-type="text"
                                 placeholder="Проект загородного дома"
                                 v-model="projectData.title"
                                 name="name">
                        </BlInput>
                        <BlInput label="Адрес клиента"
                                 rules="required"
                                 input-type="text"
                                 placeholder="Домашний адрес, или адрес офиса"
                                 v-model="projectData.client_address"
                                 name="address">
                        </BlInput>
                        <BlInput label="Клиент"
                                 rules="required"
                                 input-type="text"
                                 placeholder="Имя, Фамилия клиента, Наименование организации"
                                 v-model="projectData.client.fullName"
                                 disabled
                                 name="client">
                        </BlInput>
                      </b-col>
                      <b-col cols="12" sm="6">
                        <b-row class="p-0">
                          <b-col cols="12">
                            <p class="mini-text m-0">Статус проекта</p>
                            <v-select
                                :value="projectStatusOption"
                                :options="tabs.statusOptions"
                                :clearable="false"
                                @input="changeProjectStatus($event)"

                                label="title"
                                class="col-12 d-inline-block m-negative pr-0 pr-sm-3"
                            />
                          </b-col>
                        </b-row>
                        <b-row class="p-0">
                          <b-col cols="12">
                            <BlTextArea name="rejectReason"
                                        rules=""
                                        label="Причины отклонения"
                                        v-model="projectData.reason_of_refuse"
                                        rows=4
                                        maxRows=4
                                        class="pr-0 pr-sm-4 mt-1"
                                        placeholder="Напишите в свободной форме почему клиент отклонил проект">
                            </BlTextArea>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="12" sm="6">
                          <b-row class="p-0">
                              <b-col cols="12">
                                  <BlTextArea name="comment"
                                              rules=""
                                              label="Комментарий к проекту"
                                              v-model="projectData.comment"
                                              rows=4
                                              maxRows=4
                                              maxLength="2000"
                                              class="pr-0 pr-sm-4 mt-1"
                                              placeholder="Комментарий">
                                  </BlTextArea>
                              </b-col>
                          </b-row>
                      </b-col>
                    </b-row>
                    <b-button variant="primary" @click="postRegisterMockProject"> Сохранить</b-button>
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                      <template #button-content>
                        <div class="d-flex " style="align-items: center">
                          <img src="/icons/money.svg" width="16" height="16" style="margin-right: 4px;">
                          <div>{{ projectData.currency ? projectData.currency : 'Установить валюту' }}</div>
                          <feather-icon icon="ChevronDownIcon"/>
                        </div>
                      </template>
                      <b-dropdown-item @click="setCurrency('BYN')">BYN</b-dropdown-item>
                      <b-dropdown-item @click="setCurrency('RUB')">RUB</b-dropdown-item>
                      <b-dropdown-item @click="setCurrency('USD')">USD</b-dropdown-item>
                    </b-dropdown>
                  </b-form-group>
                </b-tab>
                <b-tab title="Заказ">
                  <b-form-group class="d-flex">
                    <b-button variant="primary" @click="placeOrder" class="mr-1 "> Разметить
                      заказ
                    </b-button>
                    <b-button variant="primary" @click="toCart" class="mr-1"> Перенести в
                      корзину
                    </b-button>
                  </b-form-group>
                </b-tab>
                <b-tab title="Экспорт">
                  <b-form-group class="mt-0 ml-sm-2 ml-0">
                    <div class="ml-1">
                      <b-row>
                        <h4>Параметры экспорта</h4>
                      </b-row>
                      <b-row class="mt-2">
                        <b-form-group label="Спецификация по:" v-slot="{ ariaDescribedby }">
                          <b-form-radio v-model="tabs.exportMode.specification" :aria-describedby="ariaDescribedby"
                                        name="export-specification" value="rooms">Помещениям и зонам
                          </b-form-radio>
                          <b-form-radio v-model="tabs.exportMode.specification" :aria-describedby="ariaDescribedby"
                                        name="export-specification" value="products">Товарам
                          </b-form-radio>
                        </b-form-group>
                      </b-row>
                      <b-row class="mt-1">
                        <b-form-checkbox class="custom-switch" v-model="tabs.exportMode.enablePhoto"
                                         switch></b-form-checkbox>
                        <p>Включить фотографии товаров</p>
                      </b-row>
                      <b-row class="mt-1 mb-2">
                        <b-form-checkbox class="custom-switch" v-model="tabs.exportMode.enableSpecsProducts"
                                         switch></b-form-checkbox>
                        <p>Включить характеристики товаров</p>
                      </b-row>
                    </div>
                    <b-button variant="primary" @click="postExportMockProject">Скачать PDF</b-button>
                    <b-button variant="primary" class="ml-2" @click="postLinkMockProject">Просмотреть PDF</b-button>
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </b-card>

            <!-- Проект -->
            <b-card class=" w-100" no-body>

              <!-- Крошки -->
              <b-row class="mt-2 pl-2 pr-2">
                <b-col cols="6 m-0 d-flex align-items-center">
                  <span>Помещения и зоны</span>
                  <!--          <BlMiniBreadcrumb>-->
                  <!--            <b-breadcrumb-item> {{breadCrumbIdItem}} </b-breadcrumb-item>-->
                  <!--          </BlMiniBreadcrumb>-->
                </b-col>
                <b-col cols="3">

                  <b-button v-if="$store.state.app.clipboard.roomId && !$store.state.app.clipboard.zoneId"
                            variant="success" @click="insertRoom">
                    Вставить помещение
                  </b-button>
                </b-col>
                <b-col cols="3">

                  <b-button variant="primary" @click="addNewRoom">Добавить помещение</b-button>
                </b-col>
              </b-row>

              <room
                    v-if="$store.state.app.project"
                    v-for="room of sortRooms"
                    :key="room.id"
                    :room="room"
                    @deleteRoom="deleteRoom"
                    @upRoom="upRoom"
                    @downRoom="downRoom"
                    @copyRoom="copyRoom">
              </room>
            </b-card>

            <!-- Футер проекта -->
            <b-card class="pl-1">
              <b-row>
                <b-col cols="12">
                  <h5 class="mb-2"> Общая сумма проекта: {{ $store.state.app.project.total_price | formatNumber }} {{ currencySymbol }}</h5>
                  <b-row class="pl-1 d-flex align-items-center w-100">
                    <div>Общая скидка</div>
                    <b-form-input v-model.number="projectData.discount" placeholder="0"
                                  class="number-input ml-1 mr-1 " size="sm" style="width: 200px"></b-form-input>
                    <div> %</div>
                    <b-button variant="primary" size="sm" @click="postRegisterMockProject" class="ml-2"> Сохранить
                    </b-button>
                  </b-row>
                  <br>
                  <h3> Итого: {{ $store.state.app.project.total_price * realDiscountCoef  | formatNumber }} {{ currencySymbol }}</h3>
                </b-col>
              </b-row>
            </b-card>

          </div>
          <div v-if="selectedType === 'room'" class="w-100">
            <!-- Проект -->
            <b-card class="" no-body>
              <!--              <h3>{{ selected.title }}</h3>-->

              <room
                  :room="selected"
                  @deleteRoom="deleteRoom"
                  @upRoom="upRoom"
                  @downRoom="downRoom"
                  @copyRoom="copyRoom">

              </room>
            </b-card>
          </div>
          <div v-if="selectedType === 'area'" class="w-100">
            <!-- Проект -->
            <b-card class="" no-body>
              <!--              <h3>{{ selected.title }}</h3>-->
              <Zone
                  :zone="selected"

                  @deleteZone="deleteZone"
                  @upZone="upZone"
                  @downZone="downZone"
                  @copyZone="copyZone"

              ></Zone>


            </b-card>
          </div>

        </div>
      </div>

      <!-- Дерево -->
      <!--      <portal to="content-renderer-sidebar-detached-left">-->
      <!--        <LeftTree></LeftTree>-->
      <!--      </portal>-->

    </b-overlay>

  </div>
</template>

<script>
import {useResponsiveAppLeftSidebarVisibility} from "@core/comp-functions/ui/app";
import _ from 'lodash';
import Room from "@/components/views/projects/nodes/Room";
import LeftTree from "@/components/views/projects/LeftTree";
import BlTextArea from "@/components/controls/BlTextArea";
import BlInput from "@/components/controls/BlInput";
import vSelect from 'vue-select';
import BlMiniBreadcrumb from "@/components/core/BlMiniBreadcrumb";
import Zone from "@/components/views/projects/nodes/Zone";
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BButton,
  BBreadcrumbItem,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BFormRadio,
} from 'bootstrap-vue'
import axios from "axios";
import router from "@/router";
import utils from "@/utils";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import JsFileDownloader from 'js-file-downloader';
import Vue from "vue";

export default {
  name: "Project",
  directives: {
    Ripple,
  },
  computed: {
    projectStatusOption() {
      for (let option of this.tabs.statusOptions) {
        console.log(option, this.projectData.status)
        if (option.value === this.projectData.status) {

          return option
        }

      }
      return null
    },
    sortRooms: function() {
      if (this.$store.state.app.project.rooms_order.length !== this.$store.state.app.project.rooms.length) {
        this.$store.state.app.project.rooms_order = this.$store.state.app.project.rooms.map((item) => item.id)
      }

      return this.$store.state.app.project.rooms_order.map((id) => this.$store.state.app.project.rooms.find(item => item.id === id))
    },
    ruble() {
      return localStorage.getItem('user.currency')
    },
    currencySymbol() {
      switch (this.ruble) {
        case 'BYN':
          return 'Br';
        case 'USD':
          return '$';
        case 'RUB':
        default:
          return '₽';
      }
    },
    realDiscountCoef() {
      const discount = this.projectData.discount
      return 1.0 - (Math.min(100.0, Math.max(0.0, discount)) / 100.0)
    },
    mqShallShowLeftSidebar() {
      useResponsiveAppLeftSidebarVisibility()
    },
    breadCrumbIdItem() {
      return (`Проект № ${this.routeId}`)

    },
    selected() {
      return this.$store.state.app.selectedProjectZone;
    },
    selectedType() {
      if (!this.selected) return null;
      if ('rooms' in this.selected) {
        return 'project'
      }
      if ('areas' in this.selected) {
        return 'room'
      }
      if ('items' in this.selected) {
        return 'area'
      }
      return null;
    }

  },
  data() {
    return {
      routeId: null,
      core: {
        isLoading: false,
      },
      tabs: {
        status: null,
        project: {
          name: '',
          address: '',
          client: '',
          rejectReason: '',
          comment: '',
          status: {
            value: 'DRAFT', title: 'Создан'
          },
        },
        statusOptions: [
          {value: 'DRAFT', title: 'Создан'},
          {value: 'IN_PROGRESS', title: 'В работе'},
          {value: 'COMPLETED', title: 'Выполнен'},
          {value: 'CANCELED', title: 'Отклонен'},
        ],
        exportMode: {
          enablePhoto: false,
          enableSpecsProducts: false,
          specification: 'rooms',

        },
      },

      rooms: [],
      project_number: '',
      dataLoading: false,
      projectData: null,
    }
  },

  components: {
    Room,
    BlMiniBreadcrumb,
    vSelect,
    BlInput,
    BFormCheckbox,
    BlTextArea,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BButton,
    BOverlay,
    LeftTree,
    BBreadcrumbItem,
    Zone,
    BFormRadio,
  },
  methods: {
    changeProjectStatus(status) {
      console.log(status)
      this.projectData.status = status.value
      // this.projectData.

    },
    setCurrency(val) {
      this.projectData.currency = val;
      api.projects.updateProject(this.projectData.id, this.projectData, val)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.dataLoading = false;
              utils.notificate(this, `Валюта проекта изменена`);
              this.loadProject(val);
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    insertRoom() {
      const roomId = this.$store.state.app.clipboard.roomId
      const projectId = this.$store.state.app.clipboard.projectId
      if (roomId && projectId) {
        api.projects.copyRoomToAnotherProject(projectId, roomId, this.projectData.id)
            .then(response => {
              this.$store.commit('app/SET_CLIPBOARD_ROOM_ID', {roomId: null, projectId: null})
              const data = response.data;
              if (data.error) {
                utils.notificateError(this, data.message, data.code)
              } else {
                this.loadProject()
                this.dataLoading = true
                this.$forceUpdate()
                utils.notificate(this, data.message)
                this.dataLoading = false
              }

            })
      }

    },
    copyZone(data) {
      this.dataLoading = true
      api.projects
          .copyZone(data.project_pk, data.room_pk, data.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.loadProject()
              this.dataLoading = true
              this.$forceUpdate()
              utils.notificate(this, data.message)
              this.dataLoading = false
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    upZone(data) {
      const currentRoom = this.projectData.rooms.find(item => item.id === data.room)
      const first = currentRoom.areas_order.indexOf(data.id)
      if (first !== 0) {
        let origin = currentRoom.areas_order[first]
        currentRoom.areas_order[first] = currentRoom.areas_order[first - 1]
        Vue.set(currentRoom.areas_order, first - 1, origin)

        api.projects
            .updateZoneOrder(this.routeId, currentRoom.id, currentRoom.title, currentRoom.areas_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.loadProject()
                this.$forceUpdate()
                this.$emit('getRooms')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Зона первая в списке.")
      }
    },
    downZone(data) {
      const currentRoom = this.projectData.rooms.find(item => item.id === data.room)

      const first = currentRoom.areas_order.indexOf(data.id)
      const end = currentRoom.areas_order.at(-1)
      const last = currentRoom.areas_order.indexOf(end)

      if (first !== last) {
        let origin = currentRoom.areas_order[first]
        currentRoom.areas_order[first] = currentRoom.areas_order[first + 1]
        Vue.set(currentRoom.areas_order, first + 1, origin)

        api.projects
            .updateZoneOrder(this.routeId, currentRoom.id, currentRoom.title, currentRoom.areas_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.loadProject()
                this.$forceUpdate()
                this.$emit('getRooms')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Зона последняя в списке.")
      }
    },
    addZone() {
      this.dataLoading = true
      api.projects
          .addZone(this.routeId, this.room.id, 'Новая зона')
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.loadProject()
              this.dataLoading = true
              utils.notificate(this, data.message)
              this.dataLoading = false
              this.$forceUpdate()
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },

    deleteZone(data) {
      this.dataLoading = true
      api.projects
          .deleteZone(data.project_pk, data.room_pk, data.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.loadProject()
              this.dataLoading = true
              this.$forceUpdate()
              utils.notificate(this, data.message)
              this.dataLoading = false
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    // Геттер проекта
    loadProject(currency=null) {
      this.dataLoading = true
      api.projects
          .getProject(this.routeId, currency)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.projectData = data.result
              this.projectData.owner.fullName = `${this.projectData.owner.last_name} ${this.projectData.owner.first_name} ${this.projectData.owner.middle_name}`;
              this.projectData.client.fullName = `${this.projectData.client.last_name} ${this.projectData.client.first_name} ${this.projectData.client.middle_name}`;
              if (this.projectData.status == 'DRAFT') {
                this.tabs.status = this.tabs.statusOptions[0]
              } else if (this.projectData.status == 'IN_PROGRESS') {
                this.tabs.status = this.tabs.statusOptions[1]
              } else if (this.projectData.status == 'COMPLETED') {
                this.tabs.status = this.tabs.statusOptions[2]
              } else this.tabs.status = this.tabs.statusOptions[3]
              this.$store.commit("app/SET_PROJECT", this.projectData)
              if (this.$store.state.app.selectedProjectZone == null) {
                this.$store.commit('app/SET_SELECTED_PROJECT_ZONE', this.projectData)
              } else {
                this.$store.commit('app/UPDATE_SELECTED_ZONE');
              }
              this.dataLoading = false;
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    resolveOrderStatusLabel(status) {
      if (status === 'COMPLETED') return 'Выполнен'
      if (status === 'IN_PROGRESS') return 'В работе'
      if (status === 'DRAFT') return 'Создан'
      else return 'Отклонен'
    },

    // updateProjectInfo() {
    //   this.dataLoading = true
    //   api.projects
    //       .getProject(this.routeId)
    //       .then((response) => {
    //         const data = response.data;
    //         if (data.error) {
    //           throw "Ошибка";
    //         } else {
    //           this.$store.commit("app/SET_PROJECT", data.result)
    //           const project = data.result
    //           // if (this.$store.state.app.selectedProjectZone === null) {
    //           //   this.$store.commit('app/SET_SELECTED_PROJECT_ZONE', project)
    //           // }
    //           this.$store.commit('app/UPDATE_SELECTED_ZONE');
    //           this.dataLoading = false;
    //         }
    //       })
    //       .catch((err) => {
    //         this.dataLoading = false
    //         const data = err.response.data;
    //         if (data && data.error) {
    //           utils.notificateError(self, data.message, data.errorCode);
    //         }
    //       });
    // },

    // Сохранение
    postRegisterMockProject() {
      api.projects.updateProject(this.projectData.id, this.projectData)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.dataLoading = false;
              utils.notificate(this, `Проект ${this.tabs.project.name} успешно обновлен`);
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
      // let fd = new FormData();
      // fd.append('title', this.projectData.title);
      // fd.append('client_address', this.projectData.client_address);
      // fd.append('status', this.tabs.status.value);
      // fd.append('reason_of_refuse', this.projectData.reason_of_refuse);
      // fd.append('discount', this.projectData.discount);
      // axios.put('/api/v1/projects/' + router.currentRoute.params.id + '/',
      //     fd,
      //     {
      //       headers: {
      //         'Content-Type': 'multipart/form-data'
      //       }
      //     })
      //     .then((response) => {
      //       const data = response.data;
      //       if (data.error) {
      //         throw "Ошибка";
      //       } else {
      //         this.dataLoading = false;
      //         utils.notificate(this, `Проект ${this.tabs.project.name} успешно обновлен`);
      //       }
      //     })
      //     .catch((err) => {
      //       this.dataLoading = false
      //       const data = err.response.data;
      //       if (data && data.error) {
      //         utils.notificateError(self, data.message, data.errorCode);
      //       }
      //     });
      // router.go(-1)
    },


    addNewRoom() {
      let title = 'Новое помещение'
      api.projects
          .addRoom(this.routeId, title)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.dataLoading = true
              utils.notificate(this, data.message)
              this.dataLoading = false
              this.$forceUpdate()
              this.loadProject()
            }
          })
          .catch((err) => {
            this.dataLoading = false
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(self, data.message, data.errorCode);
            }
          });
    },
    upRoom(item) {
      const first = this.projectData.rooms_order.indexOf(item.id)

      if (first !== 0) {
        let origin = this.projectData.rooms_order[first]
        this.projectData.rooms_order[first] = this.projectData.rooms_order[first - 1]
        Vue.set(this.projectData.rooms_order, first - 1, origin)

        api.projects
            .updateRoomOrder(this.routeId, this.projectData.title, this.projectData.rooms_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.loadProject()
                this.$forceUpdate()
                this.$emit('getProjects')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Помещенее первое в списке.")
      }
    },
    downRoom(item) {
      const first = this.projectData.rooms_order.indexOf(item.id)
      const end = this.projectData.rooms_order.at(-1)
      const last = this.projectData.rooms_order.indexOf(end)

      if (first !== last) {
        let origin = this.projectData.rooms_order[first]
        this.projectData.rooms_order[first] = this.projectData.rooms_order[first + 1]
        Vue.set(this.projectData.rooms_order, first + 1, origin)

        api.projects
            .updateRoomOrder(this.routeId, this.projectData.title, this.projectData.rooms_order)
            .then((response) => {
              const data = response.data;
              if (data.error) {
                throw "Ошибка";
              } else {
                this.loadProject()
                this.$forceUpdate()
                this.$emit('getProjects')
              }
            })
            .catch((err) => {
              this.dataLoading = false
              const data = err.response.data;
              if (data && data.error) {
                utils.notificateError(self, data.message, data.errorCode);
              }
            });
      } else {
        utils.notificate(this, "Помещение последнее в списке.")
      }
    },
    copyRoom(data) {
      api.projects
          .copyRoom(this.projectData.id, data.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.dataLoading = true
              this.$forceUpdate()
              utils.notificate(this, data.message)
              this.dataLoading = false
              this.loadProject()
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    deleteRoom(data) {
      api.projects
          .deleteRoom(this.routeId, data.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              this.dataLoading = true
              this.$forceUpdate()
              utils.notificate(this, data.message)
              this.dataLoading = false
              this.loadProject()
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    loadBasket() {
      const self = this;
      self.dataLoading = true;
      api.store.getBasket().then(response => {
        const data = response.data.result;
        self.$store.commit('app/SET_CART', data)
        self.$forceUpdate()
        self.dataLoading = false
      })
          .catch((err) => {
            self.dataLoading = false;
          });
    },

    // Мок Методы "Заказ"
    placeOrder() {
      const self = this
      this.dataLoading = true
      api.projects.sendToCart(this.$store.state.app.project.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              api.store.basketToOrder()
                  .then(response => {
                    const data = response.data;
                    const order = data.result
                    if (!order.manager) {
                      order.manager = {
                        id: null
                      }
                    }
                    if (data && !data.error) {
                      self.$store.commit('app/SET_ORDER', order)
                      self.$router.push(`/orders/${order.id}`)
                      utils.notificate(self, "Заказ успешно создан")
                    }
                  })

            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },
    toCart() {
      const self = this
      this.dataLoading = true
      api.projects.sendToCart(this.$store.state.app.project.id)
          .then((response) => {
            const data = response.data;
            if (data.error) {
              throw "Ошибка";
            } else {
              self.dataLoading = false
              self.loadBasket()
            }
          })
          .catch((err) => {
            const data = err.response.data;
            if (data && data.error) {
              utils.notificateError(this, data.message, data.errorCode);
            }
          });
    },

    // Мок Методы "Экспорт"
    loadMockExport() {
      this.tabs.exportMode.enablePhoto = true;
      this.tabs.exportMode.productSpecs = true;
      this.tabs.exportMode.zoneSpecs = true;
      this.tabs.exportMode.enableSpecsProducts = true;
    },
    postExportMockProject() {
        let pdfExportUrl = `/api/v1/projects/${router.currentRoute.params.id}/export/?specification=${this.tabs.exportMode.specification}`
        if (this.tabs.exportMode.enablePhoto) {
            pdfExportUrl += '&include_images'
        }
        if (this.tabs.exportMode.enableSpecsProducts) {
            pdfExportUrl += '&include_properties'
        }
        const currency = localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined
        if (currency) {
            pdfExportUrl += `&currency=${currency}`
        }
        try {
            new JsFileDownloader({
                url: pdfExportUrl
            })
                .then(function () {

                })
                .catch(function (error) {

                });

        } catch (e) {
            console.error(e)
        }
    },
    postLinkMockProject() {
        this.dataLoading = true
        let pdfExportUrl = `/api/v1/projects/${router.currentRoute.params.id}/export/?specification=${this.tabs.exportMode.specification}`
        if (this.tabs.exportMode.enablePhoto) {
            pdfExportUrl += '&include_images'
        }
        if (this.tabs.exportMode.enableSpecsProducts) {
            pdfExportUrl += '&include_properties'
        }
        const currency = localStorage.getItem('user.currency') ? localStorage.getItem('user.currency') : undefined
        if (currency) {
            pdfExportUrl += `&currency=${currency}`
        }
        axios(`${pdfExportUrl}`, {
            method: 'GET',
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'}
                );
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL, '_blank');
                this.dataLoading = false
            })
            .catch((err) => {
                const data = err.response.data;
                if (data && data.error) {
                    utils.notificateError(this, data.message, data.errorCode);
                }
            })
    },
  },
  mounted() {
    this.routeId = this.$router.currentRoute.params.id;
    this.loadProject()
    this.loadMockExport()
  },

}
</script>

<style scoped>

</style>

<style lang="scss">


.project-view-root {
  display: flex;
  flex-direction: row;
  overflow: auto;


}

.left-tree {


}

@media screen and (min-width: 960px) {
  .left-tree {
    margin-right: 20px;
    min-width: 20vw;
  }
}

@media screen and (max-width: 960px) {
  .project-view-root {
    flex-direction: column;
  }
}


.mini-text {
  font-size: 12px;
}

.number-input {
  max-width: 50px;
}

.m-negative {
  margin-left: -10px;
}
</style>
